import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Tab from './Tab'
import { primary, mdGrey } from '../elements'

class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeTab: this.props.children[0].props.label,
    }
  }

  onClickTabItem = tab => {
    this.setState({ activeTab: tab })
  }

  render() {
    const {
      onClickTabItem,
      props: { children },
      state: { activeTab },
    } = this

    return (
      <div className="tabs">
        <TabList className="tab-list">
          {children.map(child => {
            const { label } = child.props

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                onClick={onClickTabItem}
              />
            )
          })}
        </TabList>
        <div className="tab-content">
          {children.map(child => {
            if (child.props.label !== activeTab) return undefined
            return child.props.children
          })}
        </div>
      </div>
    )
  }
}

export default Tabs

const TabList = styled.ol`
  padding-left: 0;
  cursor: pointer;
  .tab-list-item {
    border-top: 6px solid ${mdGrey};
    display: inline-block;
    list-style: none;
    padding: 0.5rem 3.5rem 0.75rem 0;
    margin-right: 1rem;
    color: ${mdGrey};
    font-size: 0.8rem;
  }
  .tab-list-active {
    border-top: 6px solid ${primary};
    color: ${primary};
  }
`
