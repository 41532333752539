import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import ZoneIndustries from '../components/Industries'
import Values from '../components/Values'

import {
  above,
  secondary,
  Zone,
  BoxWrapper,
  Box,
  BoxInner,
  CardTitle,
  Button,
  LinkWrapper,
  LargeP,
  PageHeader,
  SidebarLayout,
  BenefitsItem,
  BenefitsSidebarList,
} from '../elements'
import hero from '../images/slideshow/ddtsi-hagerman-id.png'
import refrigerated from '../images/services/refrigerated.svg'
import dryvan from '../images/services/dryvan.svg'
import dedicated from '../images/services/dedicated.svg'
import brokerage from '../images/services/logistics.svg'

const ShippersPage = ({ data }) => {
  const postNode = {
    title: `Shippers - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Shippers - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="shippers" customTitle />

      <Zone modifiers={['darkOverlay', 'center', 'short']} hero image={hero}>
        <div className="zone-content">
          <h2>Our Service is Our Product</h2>
          <h3>
            At D&D, we are as invested in the success of your delivery as you
            are. We hold ourselves accountable to perfection while providing you
            peace of mind.
          </h3>
          <Button to="/contact">Request a Quote</Button>
        </div>
      </Zone>

      <Container>
        {/* <PageTitle>Shippers</PageTitle> */}

        <SidebarLayout
          modifiers={['translucent']}
          style={{
            margin: '1rem 0 5rem 0',
          }}
        >
          <div>
            <PageHeader>Driving Dependable Service</PageHeader>
            <LargeP>
              We assign each customer a dedicated D&D professional. We will be
              available with the real-time information on anything you need,
              anytime you need it.
            </LargeP>
            <p>
              Whether you need a long-haul or a short-haul, frozen, fresh or
              otherwise, D&D can deliver. Our capabilities provide logistics and
              supply chain management that can accommodate any service required
              with smart and efficient solutions. Our expertise and knowledge of
              the industry, logistics capabilities and vehicle capacity are
              second to none. When it comes down to it, we are most proud of the
              personalize service and dedication that our people embrace and
              pass along in order to make your business and ours a success.
            </p>
          </div>
          <div className="benefits-sidebar">
            <h4>Our Benefits </h4>
            <BenefitsSidebarList>
              <BenefitsItem>
                <i className="material-icons">memory</i> State-of-the-Art
                Technology
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">watch_later</i> On-Time Delivery
                Guarantee
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">verified_user</i> Seasoned
                Professional Team
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">satellite</i> Satellite Load
                Tracking
              </BenefitsItem>
              <BenefitsItem>
                <i className="material-icons">ring_volume</i> On Call 24/7
                Service
              </BenefitsItem>
            </BenefitsSidebarList>
          </div>
        </SidebarLayout>
        <BoxWrapper>
          <ServiceBox>
            <FlexWrapper to="/refrigerated-truckload" asModal>
              <CardImg>
                <img src={refrigerated} alt="Refrigerated Truckload" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Refrigerated Truckload</CardTitle>
                <p>
                  Our temperature control capabilities can provide the supply
                  chain solution you need with state-of-the-art
                  temperature-control and air-ride suspension.
                </p>
                <Button
                  modifiers={['outline']}
                  to="/refrigerated-truckload"
                  asModal
                >
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/dry-van-truckload" asModal>
              <CardImg>
                <img src={dryvan} alt="Dry Van Truckload" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Dry Van Truckload</CardTitle>
                <p>
                  Whether your shipment is local or over-the-road routes, we can
                  accommodate. From non-perishable foods and beverages, to
                  textile, clothing, and building products, we can deliver.
                </p>
                <Button modifiers={['outline']} to="/dry-van-truckload" asModal>
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/dedicated-regional-transport" asModal>
              <CardImg>
                <img src={dedicated} alt="Dedicated and Regional Transport" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Dedicated & Regional</CardTitle>
                <p>
                  Our team can assemble a trusted group of carriers dedicted to
                  capacity solutions to meet your business need. Whether that is
                  through dedicated lanes or regional delivery, we'll deliver.
                </p>
                <Button
                  modifiers={['outline']}
                  to="/dedicated-regional-transport"
                  asModal
                >
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>
          <ServiceBox>
            <FlexWrapper to="/brokerage-logistics" asModal>
              <CardImg>
                <img src={brokerage} alt="Brokerage and Logistics Services" />
              </CardImg>
              <HangingBoxInner>
                <CardTitle>Brokerage & Logistics</CardTitle>
                <p>
                  With a proven track record, our full-service logistics
                  department is proud to offer the capacity you need along with
                  the knowledgeable team of people you can count on.
                </p>
                <Button
                  modifiers={['outline']}
                  to="/brokerage-logistics"
                  asModal
                >
                  Learn More&nbsp;
                  <i className="material-icons">add_circle_outline</i>
                </Button>
              </HangingBoxInner>
            </FlexWrapper>
          </ServiceBox>
        </BoxWrapper>
      </Container>
      <Values />
      <ZoneIndustries />
    </Layout>
  )
}

export default ShippersPage

const ServiceBox = styled(Box)`
  flex: 0 0 100%;
  margin: 0 0 2rem 0;
  ${above.sm`
    flex: 0 0 49%;
  `};
`
const CardImg = styled.div`
  /* flex-flow: row wrap; */
  min-width: 208px;
  background: ${secondary};
`
const HangingBoxInner = styled(BoxInner)`
  margin: 0;
  height: 100%;
  ${above.lg`
    margin: 1rem 0 1rem -2rem;
    height: auto;
  `}
`
const FlexWrapper = styled(LinkWrapper)`
  flex-flow: column;
  ${above.lg`
    flex-flow: row;
  `}
`
const ServiceWrapper = styled(BoxWrapper)`
  margin-top: -6rem;
`
