import React from 'react'
import styled from 'styled-components'
import {
  above,
  primary,
  mdGrey,
  xltGrey,
  richGrey,
  LargeP,
  MaxWidthWrapper,
} from '../elements'
import { sansBoldItalic, sansItalic } from '../utilities'
import Tabs from './Tabs'

const Values = () => (
  <Zone>
    <MaxWidthWrapper>
      <div>
        <h5>Why D&D?</h5>
        <ValueTabs>
          <div label="01">
            <ValueH>People First</ValueH>
            <ValueP>
              We believe that connection and relationship is more important than
              the bottom line. If our priority is people, there will always be
              profit.
            </ValueP>
          </div>
          <div label="02">
            <ValueH>Innovative Leadership</ValueH>
            <ValueP>
              Our philosophy drives us forward in technology, process and
              approach. We are cutting edge.
            </ValueP>
          </div>
          <div label="03">
            <ValueH>Peace of Mind</ValueH>
            <ValueP>
              We feel a responsibility to help make everyone's job easier and
              more secure. We have your back.
            </ValueP>
          </div>
          <div label="04">
            <ValueH>Perspective</ValueH>
            <ValueP>
              We believe in an approach that is empathetic, proactive and
              flexible. We keep our eye on the big picture and everyone's best
              interest at heart.
            </ValueP>
          </div>
          <div label="05">
            <ValueH>Empowerment</ValueH>
            <ValueP>
              We believe that respect and trust in who we work with and who we
              work for will pay off in the long run. Inspiring human potential
              is everything.
            </ValueP>
          </div>
        </ValueTabs>
      </div>

      <BlockQuoteWrap>
        <h5>What Our Customers are Saying</h5>
        <blockquote>
          <p>
            16 years and hundreds upon hundreds of trucks we are unloading our
            very first D&D truck full of nursery stock. And the very first time
            I have ever had a driver bring drinks for my crew. Kudos on a great
            business model well done!
          </p>
          <cite>
            <a href="#">Ryan Muchow, Windsor’s Nursery</a>
          </cite>
        </blockquote>
      </BlockQuoteWrap>
    </MaxWidthWrapper>
  </Zone>
)

export default Values

const Zone = styled.section`
  padding: 4rem 2rem;
  color: white;
  position: relative;
  background: ${richGrey};
  ${above.md`
    display: flex;
  `}
`
const ValueH = styled.h3`
  color: ${primary};
  margin-bottom: 0.5rem;
`
const ValueP = styled(LargeP)`
  color: white;
  margin-bottom: 3rem;
`
const BlockQuoteWrap = styled.div`
  border-top: 2px solid ${mdGrey};
  padding-top: 3rem;
  ${above.md`
    border-top: none;
    padding-top: 0;
    flex: 0 0 49%;
    margin-left: 3rem;
  `}
  blockquote {
    color: ${xltGrey};
    padding: 0 0 1rem 32px;
    position: relative;
    p {
      font-size: 1.35rem;
      line-height: 1.85rem;
    }
    &::before {
      ${sansBoldItalic};
      content: '"';
      color: ${primary};
      font-size: 4rem;
      position: absolute;
      left: -8px;
      top: -12px;
    }
    &::after {
      content: '';
    }
  }
  cite,
  cite a {
    font-size: 1.15rem;
    text-decoration: none;
    ${sansItalic};
    color: ${mdGrey};
    &:hover {
      color: ${primary};
    }
  }
`
const ValueTabs = styled(Tabs)`
  .tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
  }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
  }

  .tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
  }
`
